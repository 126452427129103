import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { environment } from '@env/environment';
import { DISABLE_GLOBAL_EXCEPTION_HANDLING } from '@lib/interceptors';
import {
    Auth,
    BaseApiResponse,
    ChatRequest,
    ChatRoom,
    ChatRoomResponse,
    ChatUser,
    ChatUserRegistration,
    CreateRoomRequest,
    Message,
    SocketErrorHandling,
} from '@lib/interfaces';
import { FirstChatRequest } from '@lib/interfaces/chat/first-chat.interface';
import { BehaviorSubject, Observable, Subject, catchError } from 'rxjs';
import { SocketService } from '../socket/socket.service';

@Injectable({
    providedIn: 'root',
})
export class ChatService implements OnDestroy {
    chatRooms$ = new BehaviorSubject<ChatRoomResponse[]>([]);
    rateRoomId = '';

    private readonly _http = inject(HttpClient);
    private readonly _socket = inject(SocketService);
    // private readonly _sseClient = inject(SseClient);
    private readonly _chatEngineUrl = environment.chatEngineUrl;
    private readonly _apiBaseUrl = environment.apiBaseUrl;

    private readonly _destroy$ = new Subject();

    public get chatRooms(): ChatRoomResponse[] {
        return this.chatRooms$.getValue();
    }

    ngOnDestroy(): void {
        this._destroy$.complete();
        this._destroy$.unsubscribe();
    }

    subscribeRoom(uid: string): Observable<BaseApiResponse<Auth>> {
        return this._http.post<BaseApiResponse<Auth>>(this._apiBaseUrl + `/chat-link/${uid}/subscribe`, {
            uniqueId: uid,
        });
    }

    extendRoom(uid: string): Observable<BaseApiResponse<Auth>> {
        return this._http
            .post<BaseApiResponse<Auth>>(this._apiBaseUrl + `/chat-link/${uid}/extend`, {
                uniqueId: uid,
            })
            .pipe(
                catchError((error: Error) => {
                    return Promise.reject(error);
                }),
            );
    }

    createRoomChatLink(firstMessage: FirstChatRequest, uid: string): Observable<BaseApiResponse<Auth>> {
        return this._http.post<BaseApiResponse<Auth>>(this._apiBaseUrl + `/chat-link/${uid}/room`, firstMessage).pipe(
            catchError((error: Error) => {
                return Promise.reject(error);
            }),
        );
    }

    sendMessage(chatRequest: ChatRequest): void {
        this._socket.emit('message.create', chatRequest);
    }

    getMessage(): Observable<Message> {
        return this._socket.on<Message>('onMessage');
    }

    getMessageChunk(): Observable<string> {
        return this._socket.on<string>('onMessageChunk');
    }

    errorException(): Observable<SocketErrorHandling> {
        return this._socket.on<SocketErrorHandling>('exception');
    }

    // createEventSource(user: SseUser): Observable<MessageResponse> {
    //     let params = new HttpParams();
    //     params = params.set('userEmail', user.userEmail);
    //     const eventSource = new EventSource(environment.socketUrl + '?' + params.toString());

    //     return new Observable((observer) => {
    //         eventSource.onmessage = (event) => {
    //             const messageData: MessageResponse = JSON.parse(event.data);
    //             observer.next(messageData);
    //         };
    //     });
    // }

    // createEventSource(): void {
    //     this._sseClient.stream(this.createEventSource()).subscribe((response) => {
    //         if (response.type === 'error') {
    //             console.log('createEvent');
    //             const errorEvent = response as ErrorEvent;
    //             console.error(errorEvent.error, errorEvent.message);
    //         } else {
    //             const messageEvent = response as MessageEvent;
    //             console.info(messageEvent.type, messageEvent.data);
    //         }
    //     });
    // }

    onViolation(): Observable<Message> {
        return this._socket.on<Message>('onViolation');
    }

    nesyaStartThinking(): Observable<string> {
        return this._socket.on<string>('onThinkingStart');
    }

    nesyaStopThinking(): Observable<string> {
        return this._socket.on<string>('onThinkingStop');
    }

    register(request: ChatUserRegistration): Observable<ChatUser> {
        return this._http.post<ChatUser>(this._chatEngineUrl + '/users', request, {
            context: new HttpContext().set(DISABLE_GLOBAL_EXCEPTION_HANDLING, true),
        });
    }

    createRoom(createRoomRequest: CreateRoomRequest): Observable<ChatRoom> {
        return this._http.post<ChatRoom>(this._chatEngineUrl + '/rooms', createRoomRequest).pipe(
            catchError((error: Error) => {
                return Promise.reject(error);
            }),
        );
    }

    getRooms(userId: string): void {
        this._http
            .get<ChatRoomResponse[]>(this._chatEngineUrl + '/rooms/' + userId + '/info')
            .subscribe((response) => this.chatRooms$.next(response));
    }

    getRoomById(roomId: string): Observable<ChatRoom> {
        // console.log('this.rateRoomId: ', this.rateRoomId);
        return this._http.get<ChatRoom>(this._chatEngineUrl + '/rooms/' + roomId);
    }

    getMessagesByRoomId(roomId: string): Observable<Message[]> {
        return this._http.get<Message[]>(this._chatEngineUrl + '/rooms/' + roomId + '/messages');
    }

    setRateRoomId(roomId: string): void {
        this.rateRoomId = roomId;
    }

    rateSession(request: { ratings?: number | null; feedbackMessage?: string | null }): Observable<unknown> {
        // console.log('this.rateRoomId: ', this.rateRoomId);
        return this._http.post<unknown>(this._chatEngineUrl + '/rooms/' + this.rateRoomId + '/ratings', {
            ratings: request.ratings,
            feedbackMessage: request.feedbackMessage,
        });
    }
}
