import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { environment } from '@env/environment';
import {
    AppSession,
    Auth,
    BaseApiResponse,
    Cities,
    LoginRequest,
    Provinces,
    RegistrationRequest,
} from '@lib/interfaces';
import { SessionData } from '@lib/interfaces/auth/session-data.interface';
import { storage } from '@lib/utils/storage/storage.utils';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnDestroy {
    isAuthenticated$ = new BehaviorSubject<boolean>(!!storage.getItem('appSession'));
    currentSession$ = new BehaviorSubject<AppSession | null>(this._storedSession);

    private readonly _http = inject(HttpClient);
    private readonly _apiUrl = environment.apiUrl;
    private readonly _apiBaseUrl = environment.apiBaseUrl;

    private readonly _destroy$ = new Subject();

    private get _storedSession(): AppSession | null {
        return storage.getItem('appSession');
    }

    private set _storedSession(session: AppSession | null) {
        storage.setItem('appSession', session as AppSession);
    }

    public get currentSession(): AppSession | null {
        return this.currentSession$.getValue();
    }

    public get isAuthenticated(): boolean {
        return this.isAuthenticated$.getValue();
    }

    login(data: LoginRequest): Observable<BaseApiResponse<Auth>> {
        return this._http.post<BaseApiResponse<Auth>>(this._apiUrl + '/auth/login', data);
    }

    loggingHashMap(payload: LoginRequest, header: HttpHeaders): Observable<BaseApiResponse<Auth>> {
        return this._http.post<BaseApiResponse<Auth>>(this._apiBaseUrl + '/chat-link', payload, {
            headers: header,
            withCredentials: true,
        });
    }

    loggingSessionId(body: object, payload: string, header: HttpHeaders): Observable<BaseApiResponse<SessionData>> {
        return this._http.post<BaseApiResponse<SessionData>>(this._apiBaseUrl + `/chat-link/${payload}/session`, body, {
            headers: header,
            withCredentials: true,
        });
    }

    getRoomId(uid: string, header?: HttpHeaders): Observable<BaseApiResponse<Auth>> {
        return this._http.get<BaseApiResponse<Auth>>(this._apiBaseUrl + `/chat-link/${uid}`, {
            headers: header,
        });
    }

    loginWithGoogle(authToken: string): Observable<BaseApiResponse<Auth>> {
        const request = {
            token: authToken,
        };
        return this._http.post<BaseApiResponse<Auth>>(this._apiUrl + '/auth/google/login/patient', request);
    }

    register(data: RegistrationRequest): Observable<BaseApiResponse<unknown>> {
        return this._http.post<BaseApiResponse<unknown>>(this._apiUrl + '/auth/register/patient', data);
    }

    registerWithGoogle(authToken: string): Observable<BaseApiResponse<Auth>> {
        const request = {
            token: authToken,
        };
        return this._http.post<BaseApiResponse<Auth>>(this._apiUrl + '/auth/google/register/patient', request);
    }

    loadProvinces(): Observable<BaseApiResponse<Provinces[]>> {
        const response = this._http.get<BaseApiResponse<Provinces[]>>(this._apiUrl + `/province`);
        return response;
    }

    loadCities(data: number): Observable<BaseApiResponse<Cities[]>> {
        const response = this._http.get<BaseApiResponse<Cities[]>>(this._apiUrl + `/province/${data}/city`);
        return response;
    }

    logout(): void {
        storage.removeItem('appSession');
        this.isAuthenticated$.next(false);
        location.reload();
    }

    ngOnDestroy(): void {
        this._destroy$.complete();
        this._destroy$.unsubscribe();
    }

    init(): void {
        this.setSession(this._storedSession);
    }

    /**
     * Manually changes session in LocalStorage & HTML body
     *
     * @param session new session
     */
    setSession(session: AppSession | null): void {
        this._storedSession = session;
        this.currentSession$.next(session);
    }
}
