import {
    ApplicationRef,
    ComponentRef,
    EmbeddedViewRef,
    Injectable,
    Type,
    ViewContainerRef,
    inject,
} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ModalService<T> {
    private _componentRef: ComponentRef<T> | undefined;

    // private readonly _viewContainerRef = inject(ViewContainerRef);
    private readonly _appRef = inject(ApplicationRef);

    open(viewContainerRef: ViewContainerRef, component: Type<T>): void {
        if (this._componentRef) {
            return;
        }

        this._componentRef = viewContainerRef.createComponent<T>(component);

        const domElem = (this._componentRef.hostView as EmbeddedViewRef<unknown>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);
    }

    close(): void {
        if (!this._componentRef) {
            return;
        }
        this._componentRef.destroy();

        this._componentRef = undefined;
    }
}
